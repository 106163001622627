import cgtConfig from "../cgt-config";

export default class OrderBill   {

      constructor(obj){
        if(obj){
          this.billOrderID=obj.billOrderID;
          this.billReady=obj.billReady;
          this.billNo=obj.billNo;
          this.billDate=obj.billDate;
          this.billUplUserID=obj.billUplUserID;
          this.billUplTime=obj.billUplTime;
        }
    }

}