
export function SETSOFTWARESWITCHLIST(state, val) {
    state.softwareSwitchList = val
}

export function SETSOFTWAREPLUGINLIST(state, val) {
    state.softwarePluginList = val
}

export function SETSOFTWAREPRODUCTLIST(state, val) {
    state.softwareProductList = val
}

export function SETSOFTWAREPRODUCTOLDLIST(state, val) {
    state.softwareProductOldList = val
}

export function SETSOFTWAREPRODUCTCURRENTLIST(state, val) {
    state.softwareProductCurrentList = val
}

