import OrderBill from "@/models/order-bill";
import OrderCart from "@/models/order-cart";
import OrderCustomer from "@/models/order-customer";
import OrderStep from "@/models/order-step";

export default function () {
      return {
        orderList:[],
        newOrderList:[],
        completedOrderList:[],
        canceledOrderList:[],
       
        orderCart : OrderCart,
        orderCustomer : OrderCustomer,
        orderBill : OrderBill,
        orderStep : OrderStep,
        
      }
 }