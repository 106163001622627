
export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      //auth: true,
      title: 'Giriş',
    },
    component: () => import('../../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      //auth: true,
      title: 'Kayıt',
    },
    component: () => import('../../views/register.vue')
  },
]
