import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import cgtConfig from "../cgt-config";
import Home from '../views/home.vue'
import routeAuth from './_auth'
import routeProducts from './products'
import routeSoftware from './software'
import routeInternet from './internet'
import routeDealer from './dealer'
import routeCustomer from './customers'
import routeOrder from './orders'
import routeVisitor from './visitors'
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: true,
      title: 'CGT Bilişim',
    }    
  },
  {
    path: '/help',
    name: 'help',
    meta: {
      auth: true,
      title: 'Yardım',
    },
    component: () => import('../views/help.vue')
  },
    
  ...routeAuth,
  ...routeProducts,
  ...routeSoftware,
  ...routeInternet,
  ...routeDealer,
  ...routeCustomer,
  ...routeOrder,
  ...routeVisitor,
  
  /*********************** Softare ***/
  /*
  {
    path: '/softwaresp',
    name: 'softwaresp',
    meta: {
      auth: true,
      title: 'Özel Projeler',
    },
    component: () => import('../views/softwaresp/list.vue')
  },
  {
    path: '/softwarespc',
    name: 'softwarespc',
    meta: {
      auth: true,
      title: 'Özel Proje',
    },
    component: () => import('../views/softwaresp/card.vue')
  },
  {
    path: '/software',
    name: 'software',
    meta: {
      auth: true,
      title: 'Uygulamalar',
    },
    component: () => import('../views/software/list.vue')
  },
  {
    path: '/softwarec',
    name: 'softwarec',
    meta: {
      auth: true,
      title: 'Uygulama',
    },
    component: () => import('../views/software/card.vue')
  },
  {
    path: '/softwarepv',
    name: 'softwarepv',
    meta: {
      auth: true,
      title: 'Önceki Sürümler',
    },
    component: () => import('../views/softwarepv/list.vue')
  },
  {
    path: '/softwarepvc',
    name: 'softwarepvc',
    meta: {
      auth: true,
      title: 'Uygulama',
    },
    component: () => import('../views/softwarepv/card.vue')
  },    
  {
    path: '/swswitches',
    name: 'swswitches',
    meta: {
      auth: true,
      title: 'Kütüphane Switchleri',
    },
    component: () => import('../views/swswitches/list.vue')
  },
  {
    path: '/swplugins',
    name: 'swplugins',
    meta: {
      auth: true,
      title: 'Uygulama Eklentileri',
    },
    component: () => import('../views/swplugins/list.vue')
  },
  */
]

const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

export default router


router.beforeEach((to, from, next) => {
  /*
  console.log("to.meta.title : " + to.meta.title);
  console.log("AUTH USER TAG :"+cgtConfig.defAUTHUSERTAG);
  console.log("apiURL :"+cgtConfig.apiURL);
  */
  if (!to.matched.length) {
    
    next('/login');
  } else {
    
    // console.log("meta title : " + to.meta.title);
    // console.log("cgtConfig.appTitle : " + cgtConfig.appTitle);
    
    if(to.meta.title != "" ) document.title = to.meta.title || ' :: '|| cgtConfig.appTitle;
    else document.title=cgtConfig.appTitle;

    // console.log("document title : " + document.title);

    // do Weneed auth
    if(to.meta.auth){
    // now Check to Auth
    // const authToken = localStorage.getItem(cgtConfig.defAUTHUSERTAG);
     const authToken = store.state.auth.isValidLogin;
     
     if (!authToken) {
          return next('/login');
    }    
  }
  next();
}
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
  /*  console.log("NPRO Başladı");
    NProgress.start()
    */
   // this.$refs.overlay.style.display = 'block';
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  /*
  NProgress.set(1.0);
  NProgress.done(true);
  NProgress.remove();
  console.log("NPRO Bitt");
  */
 // this.$refs.overlay.style.display = 'none';
})
