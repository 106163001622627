import cgtConfig from "../cgt-config";

export default class UserCard   {

      constructor(obj){
        
        this.USRID=obj.USRID;
        this.USRName=obj.USRName;
        this.USREMail=obj.USREMail;
        this.USRPswd=obj.USRPswd;
        this.USRRole=obj.USRRole;
        this.USRAvatar=obj.USRAvatar;
        this.USRPrevLog=obj.USRPrevLog;
        this.USRLastLog=obj.USRLastLog;
        this.USRCanLogin=obj.USRCanLogin;
        this.USRPassive=obj.USRPassive;
        this.USROnCreateUserID=obj.USROnCreateUserID;
        this.USROnCreateTime=obj.USROnCreateTime;
        this.USROnUpdateUserID=obj.USROnUpdateUserID;
        this.USROnUpdateTime=obj.USROnUpdateTime;
    
    }

}