/* eslint-disable */
import cgtAxios from "../cgt-axios";
import cgtConfig from "../cgt-config";
import store from '@/store';

class AuthService {

  appVersion=cgtConfig.appVersion;
  apiURL=cgtConfig.apiURL;

  async checkLogin(email, password) {
    const axiosConnect = cgtAxios(this.apiURL);
    
    return await axiosConnect
      .post(`/loginasmaster.php`, { ver:this.appVersion, email: email, password: password })
      .then((response) => {
        return response.data;
      });
  }

  // logout
  async logout() {
    const axiosConnect = cgtAxios(this.apiURL);
    var gItem=localStorage.getItem(cgtConfig.defAUTHUSERTAG);
    if(gItem){
      var token=JSON.parse(gItem);
      //var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
      
      //var token=localStorage.getItem(cgtConfig.defAUTHUSERTAG);
      if(token){
        return await axiosConnect
        .post(`/logout.php`, { ver:this.appVersion, ca:token })
        .then((response) => {
          return response.data;
        });
  
      }
    }

      
  }

}

export default new AuthService();

