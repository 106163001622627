/*//cs-ecommerce/src/store/product/actions.js
import axios from "axios"
*/
import CGTBilisimCRMService from "../../services/cgt-cgtbilisim";

export function loadUserList({ commit }) {
  CGTBilisimCRMService.getAllUser().then(response => {
            commit("SETUSERLIST", response.data);
        })
        .catch(e => {
            console.log(e);
          //console.log(e);
        });
}

