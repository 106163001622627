<template>
  <!-- Nav -->
  <nav class="navbar navbar-dark navbar-theme-primary px-4 col-12 d-lg-none">
    <a class="navbar-brand me-lg-5" href="/">
      <img
        class="navbar-brand-dark"
        src="assets/logo.png"
        alt="CGT Bilişim"
      />
      <img
        class="navbar-brand-light"
        src="assets/logo.png"
        alt="CGT Bilişim"
      />
    </a>
    <div class="d-flex align-items-center">
      <button
        class="navbar-toggler d-lg-none collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </nav>
  <!--/ End Nav -->
</template>

<script>
export default {
  name: "AppHeader",
};
</script>

<style lang="scss" scoped></style>
