import cgtConfig from "../cgt-config";

export default class OrderStep   {

      constructor(obj){
        if(obj){
          this.orsID=obj.orsID;
          this.orsOrderID=obj.orsOrderID;
          this.orsTime=obj.orsTime;
          this.orsUserID=obj.orsUserID;
          this.orsStep=obj.orsStep;
        }
    }

}