import LogUser from "../../models/loguser";
import TokenData from "../../models/token";
import UserCard from "../../models/usercard";
import cgtConfig from "../../cgt-config";
import { login } from "./actions";

export function _loginSuccess(state,loginData) {
    // Has Token Data ?
    if(loginData){
        state.tokenData=new TokenData(loginData);
        if(state.tokenData){
            state.logUser=new LogUser(loginData);
            state.userCard=new UserCard(loginData);
            localStorage.setItem(cgtConfig.defAUTHUSERTAG, JSON.stringify(state.tokenData.TKNToken));  
            state.isValidLogin=true;
        }
    }
    //state.logUser = logUser;
};

export function _loginFailure(state) {
    state.tokenData={};
    state.logUser={};
    state.userCard={};
    state.isValidLogin = false;
    localStorage.removeItem(cgtConfig.defAUTHUSERTAG);
};

export function _logout(state) {
    state.tokenData={};
    state.logUser={};
    state.userCard={};
    state.isValidLogin = false;
    localStorage.removeItem(cgtConfig.defAUTHUSERTAG);
    localStorage.removeItem("logUser");
};


