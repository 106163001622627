export default class TokenData  {

    constructor(obj){
        this.TKNUserID=obj.TKNUserID;
        this.TKNUserTAG=obj.TKNUserTAG;
        this.TKNToken=obj.TKNToken;
        this.TKNStartActivity=obj.TKNStartActivity;
        this.TKNLastActivity=obj.TKNLastActivity;
  
    }

}