import router from "../../router";
import productService from "@/services/cgt-product";


/*** Products */
export function loadProductList({ commit }) {
    productService.getProductList().then(response => {
        commit("_setProductListArray", response.data);
    })
    .catch(e => {
        console.log(e);
    });
}

