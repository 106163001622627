/* eslint-disable */
import cgtAxios from "../cgt-axios";
import cgtConfig from "../cgt-config";

class CGTBilisimCRMService {
  appVersion=cgtConfig.appVersion;
  apiURL=cgtConfig.apiURL;

  async getAll() {
    const axiosConnect = cgtAxios(this.apiURL);
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    return await axiosConnect.post(`/view/products.php`, { ver:this.appVersion, ca:token });
  }  
/*
  get(id) {
    return http.post(`/view/product.php`,{ pId: id });
  }

  newCustomer(dataForm) {
    return http.post(`/view/register.php`,dataForm);
  }
*/
  getAllBayi() {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
    return axiosConnect.post(`/view/categories.php`,{ ca: token, ver:this.appVersion });
  }

  getAllUser() {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
    return axiosConnect.post(`/view/categories.php`,{ ca: token, ver:this.appVersion });
  }

/*
  create(data) {
    return http.post("/tutorials", data);
  }

  update(id, data) {
    return http.put(`/tutorials/${id}`, data);
  }

  delete(id) {
    return http.delete(`/tutorials/${id}`);
  }

  deleteAll() {
    return http.delete(`/tutorials`);
  }

  findByTitle(title) {
    return http.get(`/tutorials?title=${title}`);
  }
  */
}

export default new CGTBilisimCRMService();

