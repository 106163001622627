import router from "../../router";
import visitorService from "@/services/cgt-visitors";

/*** Order List(s) */
export function loadVisitorsList({ commit }) {
    visitorService.getVisitors().then(response => {
        commit("_setTOVisitorsList", response.data);
    })
    .catch(e => {
        console.log(e);
    });
}
