
export default [
  {
    path: '/products',
    name: 'Products',
    meta: {
      auth: true,
      title: 'Hizmet/Ürünler',
    },
    component: () => import('../../views/products/list.vue')
  },
]
