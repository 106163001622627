
export default [
  {
    path: '/customerlist',
    name: 'customerlist',
    meta: {
      auth: true,
      title: 'Müşteri Listesi',
    },
    component: () => import('../../views/customers/list.vue')
  },
  {
    path: '/customerc/',
    name: 'customer-add',
    meta: {
      auth: true,
      title: 'Müşteri',
    },
    component: () => import('../../views/customers/card.vue')
  },    
  {
    path: '/customerc/:id',
    name: 'customer-card',
    meta: {
      auth: true,
      title: 'Müşteri',
    },
    component: () => import('../../views/customers/card.vue')
  },      
  {
    path: '/customerwaitlist',
    name: 'customerwaitlist',
    meta: {
      auth: true,
      title: 'Müşteri Bekleme Listesi',
    },
    component: () => import('../../views/customers/waitlist.vue')
  },
  
]

