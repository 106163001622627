import cgtConfig from "../cgt-config";

export default class OrderCart   {

      constructor(obj){
        if(obj){
          this.ordID=obj.ordID;
          this.ordLogIP=obj.ordLogIP;
          this.ordTime=obj.ordTime;
          this.ordReferansID=obj.ordReferansID;
          this.ordCustID=obj.ordCustID;
          this.ordItemCount=obj.ordItemCount;
          this.ordNetTotal=obj.ordNetTotal;
          this.ordTaxTotal=obj.ordTaxTotal;
          this.ordTotal=obj.ordTotal;
        }
    }

}