import router from "../../router";
import orderService from "@/services/cgt-order";

/*** Order List(s) */
export function loadNewOrderList({ commit }) {
    orderService.getNewOrders().then(response => {
        commit("_setTONewOrderList", response.data);
    })
    .catch(e => {
        console.log(e);
    });
}

export function loadCompletedOrderList({ commit }) {
    orderService.getCompletedOrders().then(response => {
        commit("_setTOCompletedOrderList", response.data);
    })
    .catch(e => {
        console.log(e);
    });
}

export function loadCanceledOrderList({ commit }) {
    orderService.getCanceledOrders().then(response => {
        commit("_setTOCanceledOrderList", response.data);
    })
    .catch(e => {
        console.log(e);
    });
}

/*** Order Mark(s) */
export function markAsPaid({ commit, getters }, payload) {

    orderService.markAsPaid(payload).then(response => {
        loadNewOrderList({ commit });
        
        return true;
    })
      .catch(error => {
        console.log(error);
        return false;
      });
}

export function markAsCanceled({ commit, getters }, payload) {

    orderService.markAsCanceled(payload).then(response => {
        loadNewOrderList({ commit });
        return true;
    })
      .catch(error => {
        console.log(error);
        return false;
      });
  
}

export function removeCanceledMark({ commit, getters }, payload) {

    orderService.removeCanceledMark(payload).then(response => {
        loadCanceledOrderList({ commit });
        return true;
    })
      .catch(error => {
        console.log(error);
        return false;
      });
  
}
export function orderProcess({ commit, getters }, payload) {

    orderService.processOrder(payload).then(response => {
        loadNewOrderList({ commit });
        return true;
    })
      .catch(error => {
        console.log(error);
        return false;
      });
  
}


