/* eslint-disable */
import cgtAxios from "../cgt-axios";
import cgtConfig from "../cgt-config";



class CRMCustomerService {
  appVersion=cgtConfig.appVersion;
  apiURL=cgtConfig.apiURL;
  authTAG=cgtConfig.defAUTHUSERTAG;

  /*** Customer */
  async getCustomerList() {
    const axiosConnect = cgtAxios(this.apiURL);
    var token=JSON.parse(localStorage.getItem(this.authTAG));
    return await axiosConnect.post(`/customers/list.php`, { ver:this.appVersion, ca:token });
  }  

  async getCustomerAddressList(id) {
    const axiosConnect = cgtAxios(this.apiURL);
    var token=JSON.parse(localStorage.getItem(this.authTAG));
    return await axiosConnect.post(`/customers/getaddr.php`, { ver:this.appVersion, ca:token,id:id });
  }  

  async addCustomer(data) {
    const axiosConnect = cgtAxios(this.apiURL);
    var token=JSON.parse(localStorage.getItem(this.authTAG));
    return await axiosConnect.post(`/customers/add.php`, { ver:this.appVersion, ca:token,...data });
  }  

  async updateCustomer(data) {
    const axiosConnect = cgtAxios(this.apiURL);
    var token=JSON.parse(localStorage.getItem(this.authTAG));
    return await axiosConnect.post(`/customers/update.php`, { ver:this.appVersion, ca:token,...data });
  }  

  async getCustomer(id) {
    const axiosConnect = cgtAxios(this.apiURL);
    var token=JSON.parse(localStorage.getItem(this.authTAG));
    return await axiosConnect.post(`/customers/get.php`, { ver:this.appVersion, ca:token ,id:id });
  }  

  async delCustomer(id) {
    const axiosConnect = cgtAxios(this.apiURL);
    var token=JSON.parse(localStorage.getItem(this.authTAG));
    return await axiosConnect.post(`/customers/del.php`, { ver:this.appVersion, ca:token ,id:id });
  }  
  /*** Customer Wait List */
  async getCustomerWaitList() {
    const axiosConnect = cgtAxios(this.apiURL);
    var token=JSON.parse(localStorage.getItem(this.authTAG));
    return await axiosConnect.post(`/customers/waitlist.php`, { ver:this.appVersion, ca:token });
  }  
  
  async delWaitingCustomer(email) {
    const axiosConnect = cgtAxios(this.apiURL);
    var token=JSON.parse(localStorage.getItem(this.authTAG));
    return await axiosConnect.post(`/customers/delwcust.php`, { ver:this.appVersion, ca:token ,email:email });
  }  

}

export default new CRMCustomerService();

