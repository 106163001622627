
export default [
  {
    path: '/neworders',
    name: 'NewOrders',
    meta: {
      auth: true,
      title: 'Yeni Siparişler',
    },
    component: () => import('../../views/orders/list-new.vue')
  },
  {
    path: '/canceled-orders',
    name: 'CanceledOrders',
    meta: {
      auth: true,
      title: 'İptal Siparişler',
    },
    component: () => import('../../views/orders/list-canceled.vue')
  },
  {
    path: '/completed-orders',
    name: 'CompletedOrders',
    meta: {
      auth: true,
      title: 'Tamamlanan Siparişler',
    },
    component: () => import('../../views/orders/list-completed.vue')
  },

]
