import axios from 'axios';
import { useLoading } from 'vue-loading-overlay';
// import nProgress from 'nprogress';
import Swal from 'sweetalert2'
import router from './router';

const $loading = useLoading({
  // options
});

const cgtAxios = (dynamicBaseURL) => {

  let loader = null;
  let errorStatus = 0;
  let errorMessage = 0;
  // axios instance for making requests
  const axiosInstance = axios.create({
    baseURL: dynamicBaseURL
  });

  axiosInstance.interceptors.request.use(config => {
    // simulate AJAX
    //nProgress.start();
    loader = $loading.show({
      // Optional parameters
      //container: this.fullPage ? null : this.$refs.formContainer,
      container: null,
      canCancel: false,
      color: "#282828",
      'background-color': '#282828',
      'lock-scroll': true,
      loader: 'bars',
      height: 120,
      width: 120,
      //onCancel: this.onCancel,
    });
    // simulate AJAX
    /*  setTimeout(() => {
          loader.hide()
      }, 5000)    
      */
    return config
  })

  // before a response is returned stop nprogress
  axiosInstance.interceptors.response.use(response => {
    //NProgress.done(true);
    //NProgress.remove();
    if (loader) {
      loader.hide();
      loader = null;
    }

    return response
  })

  axiosInstance.interceptors.response.use(response => {
    //NProgress.done(true);
    //NProgress.remove();
    if (loader) {
      loader.hide();
      loader = null;
    }
    return response
  },
    error => {

      if (loader) {
        loader.hide();
        loader = null;
      }

      errorStatus = error.response.status;
      errorMessage = error.message;

      switch (errorStatus) {
        case 401: {
          errorMessage = "Kullanıcı geçersiz";
        } break;
        case 403: {
          //errorMessage="Kullanıcı geçersiz";
        } break;

      }

       Swal.fire({
        title: 'Hata !',
        text: errorMessage,//error.message,
        icon: 'error',
        confirmButtonText: 'Tamam',
        confirmButtonColor:"#f00"
      }).then((result)=>{
        /*
        console.log(error.response.status);
        console.log(errorStatus);
        console.log(result.value);
  */
        if((errorStatus==403) ||
        (errorStatus==485) ||
        (errorStatus==486) )
        {
            router.push('/login');
        }
  
  
      });


      /*
      if (response.responseJSON) {
      
      error = response.responseJSON.error;
      
      }
    */


      /*
          console.log(error.toJSON());
          //console.log("Status : ".error)
          console.log(error)
          console.log()
          */
      var e = new Error();
      e = error;

      throw e;
      // throw new Error({message:error.message,status:error.response.status});
      /*
      if(error.response.status==403){ // token
       router.push('/login');
      }
 
     */


    })

  return axiosInstance;
};

export default cgtAxios;