<template>
<!-- Start Footer Area -->
<footer class="bg-white rounded shadow p-2 mt-2" style="background-color: #48a9a6 !important;">
    <div class="row">
        <div class="col-12 col-md-4 col-xl-6 mb-4 mb-md-0">
            <p class="mb-0 text-center text-lg-start">© 2002-<span class="current-year"></span> <a class="text-primary fw-normal" href="https://mitsoft.net" target="_blank">Mitsoft</a></p>
        </div>
        <div class="col-12 col-md-8 col-xl-6 text-center text-lg-start">
            <!-- List -->
            <ul class="list-inline list-group-flush list-group-borderless text-md-end mb-0">
                <li class="list-inline-item px-0 px-sm-2">
                    <a href="https://mitsoft.net" target="_blank">mitsoft.net</a>
                </li>
                <li class="list-inline-item px-0 px-sm-2">
                    <a href="https://cgtbilisim.com" target="_blank">cgtbilisim.com</a>
                </li>

                <li class="list-inline-item px-0 px-sm-2">
                    <a href="http://esegoca.com" target="_blank">esegoca.com</a>
                </li>
                <li class="list-inline-item px-0 px-sm-2">
                    <a href="https://ckara.com" target="_blank">ckara.com</a>
                </li>
            </ul>
        </div>
    </div>
</footer>
<!-- /End Footer Area -->
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>
<style scoped lang="scss">
/*=============================
	20. Start Footer CSS
===============================*/
footer a{
    color:#fff !important;
}
</style>