/*//cs-ecommerce/src/store/product/actions.js
import axios from "axios"
*/
import router from "../../router";
import softwareService from "../../services/software-service";

export function loadPeopleList({ commit }) {
  softwareService.getAllPeople().then(response => {
            commit("SETPEOPLELIST", response.data);
            //
        })
        .catch(e => {
            console.log(e);
          //console.log(e);
        });
}

// Dealer
export function addDealer({ commit, getters }, payload) {
  softwareService.addPerson(payload).then(response => {
        loadPeopleList({commit});
          router.push('/dealerlist');
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

export function updateDealer({ commit, getters }, payload) {
  softwareService.updatePerson(payload).then(response => {
    loadPeopleList({commit});
    router.push('/dealerlist');
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

// Person
export function addPerson({ commit, getters }, payload) {
  softwareService.addPerson(payload).then(response => {
        loadPeopleList({commit});
          //router.push(listRoute);
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

export function updatePerson({ commit, getters }, payload) {
  softwareService.updatePerson(payload).then(response => {
    loadPeopleList({commit});
    router.push('/peoplelist');
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

export function delPerson({ commit, getters }, payload) {
  softwareService.delPerson(payload).then(response => {
    loadPeopleList({commit});
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}
