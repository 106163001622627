/* eslint-disable */
import { createStore } from 'vuex'
import auth from './auth'
import products from './products'

import people  from './people'
import user from './user'
import software from './software'

import createPersistedState from 'vuex-persistedstate'
import customers from './customers'
import orders from './orders'
import visitors from './visitors'


export default createStore({
  /*
  state: {
  },
  mutations: {
  },
  actions: {
  },
  */
  modules: {
    auth,
    customers,
    products,
    orders,
    people,
    user,
    software,
    visitors
  },
  plugins: [createPersistedState()],
})

