
export default [
  {
    path: '/visitors',
    name: 'Visitors',
    meta: {
      auth: true,
      title: 'Ziyaretciler',
    },
    component: () => import('../../views/visitors/list.vue')
  },

]
