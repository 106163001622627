<template>
  <div id="overlay"></div>
    <div v-show="!isValidVersion()" style="position: absolute;margin-left: auto;margin-right: auto;left:0;
  right:0;">
        <h1 style="font-weight: bold;">Lütfen tarayıcı belleğinizi temizleyiniz</h1>          
        <h2>Uygulama sürümünüz geçersiz.</h2>
        <h3>Sunucu Sürümü : {{dbVersion}}</h3>
        <h3>Uygulama Sürümü : {{appVersion}}</h3>

    </div>  
    <app-header v-if="isLogged()"></app-header>
    <app-sidebar-left v-if="isLogged()"></app-sidebar-left>
    <main class='content' :style='mainContentStyle'>
      <app-page-in-nav v-if="isLogged()"></app-page-in-nav>        
      <div class="contentBody">
        <router-view></router-view>
      </div>
    </main>
    <app-footer v-if="isLogged()"></app-footer>  
</template>

<script>

import AppHeader from "./layout/Header.vue"
import AppFooter from "./layout/Footer.vue"
import PageInNav from "./layout/PageInNav.vue"
import AppSideBarLeft from "./layout/SideBarLeft.vue"
import cgtConfig from "./cgt-config";
import { mapState, mapActions } from 'vuex'
import store from "./store";
import router from "./router";

export default {
    name: 'app',
    data() {
    return {
      dbErrorMsg:"ss",
      appVersion: require('../package.json').version,
      appSignature: require('../package.json').signature,
      dbVersion: "",
    };
  },    
    components:{
         'app-header':AppHeader,
         'app-page-in-nav':PageInNav,
         'app-footer':AppFooter,
         'app-sidebar-left':AppSideBarLeft,
     },
    computed: {
        ...mapState('auth', ['isValidLogin']),      
        ...mapState({
            alert: state => state.alert
        }),
        mainContentStyle(){
          let styleText = '';
          if(this.isValidLogin) styleText = '';
          else styleText = 'margin:0 !important;';
          return styleText;

        }
    },
    async mounted() {
        try {
          this.dbVersion= await fetch(cgtConfig.apiURL+'/version.php').then(function(response) {
            return response.text();
        });         
        } catch (ex) {
          console.log("Error in fetch"+ex.response);
        }        
    },    
    methods: {
      isLogged(){
        
        //const authToken = localStorage.getItem(cgtConfig.defAUTHUSERTAG);
        //const authToken = store.auth.isValidLogin;
        const authToken = this.isValidLogin;
        if(!authToken){
          router.push('/login');
          
        }
        if (!authToken) return 0;        
        return 1;
      },
      isValidVersion(){
        if(this.appVersion!==this.dbVersion) return false;
        return true;
      }

    },
  }
</script>


<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 0px !important;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.content{
  min-height: 100vh;

}
.contentBody{
  min-height: 50vh;
  padding: 0 1rem 0 1rem;

}

#overlay{
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 11000; /* Specify a stack order in case you're using a different order for other elements */
  cursor: default; /* Add a pointer on hover */  
}


</style>
