
export default [
  {
    path: '/softwaresp',
    name: 'softwaresp',
    meta: {
      auth: true,
      title: 'Özel Projeler',
    },
    component: () => import('../../views/softwaresp/list.vue')
  },
  {
    path: '/softwarespc',
    name: 'softwarespc',
    meta: {
      auth: true,
      title: 'Özel Proje',
    },
    component: () => import('../../views/softwaresp/card.vue')
  },
  {
    path: '/software',
    name: 'software',
    meta: {
      auth: true,
      title: 'Uygulamalar',
    },
    component: () => import('../../views/software/list.vue')
  },
  {
    path: '/software/:id',
    name: 'software-card',
    meta: {
      auth: true,
      title: 'Uygulama',
    },
    component: () => import('../../views/software/card.vue')
  },
  {
    path: '/softwarepv',
    name: 'softwarepv',
    meta: {
      auth: true,
      title: 'Önceki Sürümler',
    },
    component: () => import('../../views/softwarepv/list.vue')
  },
  {
    path: '/softwarepvc/',
    name: 'softwarepv-add',
    meta: {
      auth: true,
      title: 'Uygulama',
    },
    component: () => import('../../views/softwarepv/card.vue')
  },    
  {
    path: '/softwarepvc/:id',
    name: 'softwarepv-card',
    meta: {
      auth: true,
      title: 'Uygulama',
    },
    component: () => import('../../views/softwarepv/card.vue')
  },    
  {
    path: '/swswitches',
    name: 'swswitches',
    meta: {
      auth: true,
      title: 'Kütüphane Switchleri',
    },
    component: () => import('../../views/swswitches/list.vue')
  },
  {
    path: '/swplugins',
    name: 'swplugins',
    meta: {
      auth: true,
      title: 'Uygulama Eklentileri',
    },
    component: () => import('../../views/swplugins/list.vue')
  },
]
