
export default [
  {
    path: '/webapp',
    name: 'webapp',
    meta: {
      auth: true,
      title: 'WEB Uygulamaları',
    },
    component: () => import('../../views/webapp/list.vue')
  },
  {
    path: '/website',
    name: 'website',
    meta: {
      auth: true,
      title: 'WEB Siteleri',
    },
    component: () => import('../../views/website/list.vue')
  },
  {
    path: '/webcms',
    name: 'webcms',
    meta: {
      auth: true,
      title: 'WEB CMS Siteleri',
    },
    component: () => import('../../views/webcms/list.vue')
  },
  {
    path: '/webtemplate',
    name: 'webtemplate',
    meta: {
      auth: true,
      title: 'WEB Temaları',
    },
    component: () => import('../../views/webtemplate/list.vue')
  },
  {
    path: '/webplugin',
    name: 'webplugin',
    meta: {
      auth: true,
      title: 'WEB Eklentileri',
    },
    component: () => import('../../views/webplugin/list.vue')
  },

]
