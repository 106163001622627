
export default [
  {
    path: '/dealerlist',
    name: 'dealerlist',
    meta: {
      auth: true,
      title: 'Bayi Listesi',
    },
    component: () => import('../../views/dealer/list.vue')
  },
  {
    path: '/dealerc/',
    name: 'dealer-add',
    meta: {
      auth: true,
      title: 'Bayi',
    },
    component: () => import('../../views/dealer/card.vue')
  },    
  {
    path: '/dealerc/:id',
    name: 'dealer-card',
    meta: {
      auth: true,
      title: 'Bayi',
    },
    component: () => import('../../views/dealer/card.vue')
  },      
]
