import router from "../../router";
import softwareService from "../../services/software-service";


/*** Switches */
export function loadSoftwareSwitchList({ commit }) {
  softwareService.getAllSwitches().then(response => {
            commit("SETSOFTWARESWITCHLIST", response.data);
        })
        .catch(e => {
            console.log(e);
          //console.log(e);
        });
}

export function addSwitch({ commit, getters }, payload) {
  softwareService.addSwitch(payload).then(response => {
    loadSoftwareSwitchList({commit});
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

export function delSwitch({ commit, getters }, payload) {
  softwareService.delSwitch(payload).then(response => {
    loadSoftwareSwitchList({commit});
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

/*** Plugins */
export function loadSoftwarePluginList({ commit }) {
  softwareService.getAllPlugins().then(response => {
            commit("SETSOFTWAREPLUGINLIST", response.data);
        })
        .catch(e => {
            console.log(e);
        });
}

export function addPlugin({ commit, getters }, payload) {
  softwareService.addPlugin(payload).then(response => {
    loadSoftwarePluginList({commit});
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

export function delPlugin({ commit, getters }, payload) {
  softwareService.delPlugin(payload).then(response => {
    loadSoftwarePluginList({commit});
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

/*** Products */
export function loadSoftwareProductList({ commit }) {
  softwareService.getAllProducts().then(response => {
            commit("SETSOFTWAREPRODUCTLIST", response.data);
        })
        .catch(e => {
            console.log(e);
        });
}

export function addProduct({ commit, getters }, payload) {
  softwareService.addProduct(payload).then(response => {
    loadSoftwareProductList({commit});
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

export function delProduct({ commit, getters }, payload) {
  softwareService.delProduct(payload).then(response => {
    loadSoftwareProductList({commit});
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

/*** Products - Prev */
export function loadSoftwareProductOldList({ commit }) {
  softwareService.getPrevProducts().then(response => {
            commit("SETSOFTWAREPRODUCTOLDLIST", response.data);
        })
        .catch(e => {
            console.log(e);
        });
}

export function addPrevProduct({ commit, getters }, payload) {
  softwareService.addProduct(payload).then(response => {
    loadSoftwareProductOldList({commit});
      router.push('/softwarepv');
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

export function delPrevProduct({ commit, getters }, payload) {
  softwareService.delProduct(payload).then(response => {
    loadSoftwareProductOldList({commit});
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

/*** Products - Current */
export function loadSoftwareProductCurrentList({ commit }) {
  softwareService.getCurrentProducts().then(response => {
            commit("SETSOFTWAREPRODUCTCURRENTLIST", response.data);
        })
        .catch(e => {
            console.log(e);
        });
}

export function addCurrentProduct({ commit, getters }, payload) {
  softwareService.addProduct(payload).then(response => {
    loadSoftwareProductCurrentList({commit});
      router.push('/software');
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}

export function delCurrentProduct({ commit, getters }, payload) {
  softwareService.delProduct(payload).then(response => {
    loadSoftwareProductCurrentList({commit});
          return true;
        })
        .catch(error => {
          console.log(error);
          return false;
        });

}


/*export function register({ commit }) {
  commit('_loginRequest', { email });

  authService.logout();
  commit('_logout');
}

register({ dispatch, commit }, user) {
  commit('registerRequest', user);

  userService.register(user)
      .then(
          user => {
              commit('registerSuccess', user);
              router.push('/login');
              setTimeout(() => {
                  // display success message after route change completes
                  dispatch('alert/success', 'Registration successful', { root: true });
              })
          },
          error => {
              commit('registerFailure', error);
              dispatch('alert/error', error, { root: true });
          }
      );
}

*/
