export default class LogUser   {

    constructor(obj){
        this.LOGID=obj.LOGID;
        this.LOGName=obj.LOGName;
        this.LOGEMail=obj.LOGEMail;
        this.LOGPswd=obj.LOGPswd;
        this.LOGRole=obj.LOGRole;
        this.LOGAvatar=obj.LOGAvatar;
        this.LOGPrevLog=obj.LOGPrevLog;
        this.LOGLastLog=obj.LOGLastLog;
    }

}