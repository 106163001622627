<template>
  <!-- Left Side Bar -->
  <nav id="sidebarMenu" class="sidebar d-lg-block bg-gray-800 text-white collapse" data-simplebar>
    <div class="sidebar-inner px-4 pt-3">
      <div class="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
        <div class="d-flex align-items-center">
          <div class="avatar-lg me-4">
            <img class="card-img-top rounded-circle border-white" :src="logAvatar()" alt="logName()">
          </div>
          <div class="d-block">
            <h2 class="h5 mb-3">Hi, {{ logName() }}</h2>
            <a class="btn btn-secondary btn-sm d-inline-flex align-items-center" @click="handleLogout()">
              <svg class="icon icon-xxs me-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
              </svg>
              Sign Out
            </a>
          </div>
        </div>
        <div class="collapse-close d-md-none">
          <a href="#sidebarMenu" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
            aria-expanded="true" aria-label="Toggle navigation">
            <svg class="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </a>
        </div>
      </div>
      <ul class="nav flex-column pt-3 pt-md-0">
        <li class="nav-item">
          <router-link to="/" class="nav-link d-flex align-items-center">
            <span class="sidebar-icon">
              <img src="/assets/cgt-circle-w.svg" height="20" width="20" alt="Volt Logo">
            </span>
            <span class="mt-1 ms-1 sidebar-text">CGT Manager</span>
          </router-link>
        </li>
        <li class="nav-item" :class="{ 'active': ($route.path == '/') }">
          <router-link to="/" class="nav-link">
            <span class="sidebar-icon">
              <i class="fa fa-house"></i>
            </span>
            <span class="sidebar-text">Ana Sayfa</span>
          </router-link>
        </li>


        
        <li role="separator" class="dropdown-divider mt-4 mb-3 border-gray-700"></li>
        <li class="nav-item" :class="{ 'active': ($route.path === '/neworders') }">
          <router-link to="/neworders" class="nav-link">
            <span class="sidebar-icon">
              <i class="fa fa-cart-shopping text-success"></i>
            </span>
            <span class="sidebar-text text-success">Yeni siparişler</span>
          </router-link>
        </li>
        <li class="nav-item" :class="{ 'active': ($route.path === '/completed-orders') }">
          <router-link to="/completed-orders" class="nav-link">
            <span class="sidebar-icon">
              <i class="fa fa-cart-plus text-primary"></i>
            </span>
            <span class="sidebar-text text-primary">Tamamlanan sipariş</span>
          </router-link>
        </li>
        <li class="nav-item" :class="{ 'active': ($route.path === '/canceled-orders') }">
          <router-link to="/canceled-orders" class="nav-link">
            <span class="sidebar-icon">
              <i class="fa fa-cart-arrow-down text-danger"></i>
            </span>
            <span class="sidebar-text text-danger">İptal sipariş</span>
          </router-link>
        </li>
        <li role="separator" class="dropdown-divider mt-4 mb-3 border-gray-700"></li>



        <li class="nav-item" :class="{ 'active': ($route.path === '/customerwaitlist') }">
          <router-link to="/customerwaitlist" class="nav-link">
            <span class="sidebar-icon">
              <i class="fa fa-person"></i>
            </span>
            <span class="sidebar-text">Bekleyen Müşteri</span>
          </router-link>
        </li>
        <li class="nav-item" :class="{ 'active': ($route.path === '/customerlist') }">
          <router-link to="/customerlist" class="nav-link">
            <span class="sidebar-icon">
              <i class="fa fa-person"></i>
            </span>
            <span class="sidebar-text">Müşteriler</span>
          </router-link>
        </li>



        <li role="separator" class="dropdown-divider mt-4 mb-3 border-gray-700"></li>



        <li class="nav-item" :class="{ 'active': ($route.path === '/products') }">
          <router-link to="/products" class="nav-link">
            <span class="sidebar-icon">
              <i class="fa fa-box"></i>
            </span>
            <span class="sidebar-text">Ürün & Hizmetler</span>
          </router-link>
        </li>



        <li role="separator" class="dropdown-divider mt-4 mb-3 border-gray-700"></li>






        <!-- Customer Drop Down Menu  Start 
        <li class="nav-item">
          <span class="nav-link  collapsed  d-flex justify-content-between align-items-center" data-bs-toggle="collapse"
            data-bs-target="#submenu-customer">
            <span>
              <span class="sidebar-icon">
                <i class="fa-solid fa-person"></i>
              </span>
              <span class="sidebar-text">Müşteriler</span>
            </span>
            <span class="link-arrow">
              <svg class="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
            </span>
          </span>
          <div class="multi-level collapse " role="list" id="submenu-customer" aria-expanded="false">
            <ul class="flex-column nav">
              <li class="nav-item ">
                <router-link class="nav-link" to="/customerwaitlist">
                  <span class="sidebar-icon">
                    <i class="fa fa-clock"></i>
                  </span>

                  <span class="sidebar-text">Bekleyenler</span>
                </router-link>
              </li>
              <li class="nav-sub-separator"></li>
              <li class="nav-item ">
                <router-link class="nav-link" to="/customerlist">
                  <span class="sidebar-icon">
                    <i class="fa fa-person"></i>
                  </span>
                  <span class="sidebar-text">Müşteri Listesi</span>
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        -- Customer Menu  End -->


        <li class="nav-item">
          <span class="nav-link  collapsed  d-flex justify-content-between align-items-center" data-bs-toggle="collapse"
            data-bs-target="#submenu-settings">
            <span>
              <span class="sidebar-icon">
                <svg class="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clip-rule="evenodd"></path>
                </svg>
              </span>
              <span class="sidebar-text">Ayarlar</span>
            </span>
            <span class="link-arrow">
              <svg class="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"></path>
              </svg>
            </span>
          </span>
          <div class="multi-level collapse " role="list" id="submenu-settings" aria-expanded="false">
            <ul class="flex-column nav">
              <li class="nav-item ">
                <router-link class="nav-link" to="/customerlist">
                  <span class="sidebar-text">Switch List</span>
                </router-link>
              </li>
              <li class="nav-item ">
                <router-link class="nav-link" to="/customerlist">
                  <span class="sidebar-text">Plugin List</span>
                </router-link>
              </li>

            </ul>
          </div>
        </li>

        <li class="nav-item" :class="{ 'active': ($route.path === '/visitors') }">
          <router-link to="/visitors" class="nav-link">
            <span class="sidebar-icon">
              <i class="fa fa-box"></i>
            </span>
            <span class="sidebar-text">Ziyaretçiler</span>
          </router-link>
        </li>

        <li role="separator" class="dropdown-divider mt-4 mb-3 border-gray-700"></li>

        <li class="nav-item">
          <router-link target="_blank" to="/help" class="nav-link d-flex align-items-center">
            <span class="sidebar-icon">
              <svg class="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd"></path>
              </svg>
            </span>
            <span class="sidebar-text">Yardım <span
                class="badge badge-sm bg-secondary ms-1 text-gray-800">v1.0.3</span></span>
          </router-link>
        </li>
        <li class="nav-item">
          <a href="https://mitsoft.net" target="_blank" class="nav-link d-flex align-items-center">
            <span class="sidebar-icon">
              <img src="/assets/cgt-circle-w.svg" height="20" width="28" alt="Mitsoft">
            </span>
            <span class="sidebar-text">Mitsoft</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="https://mitsoft.net/download"
            class="btn btn-secondary d-flex align-items-center justify-content-center btn-upgrade-pro">
            <span class="sidebar-icon d-inline-flex align-items-center justify-content-center">
              <svg class="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                  clip-rule="evenodd"></path>
              </svg>
            </span>
            <span>Program İndir</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
  <!--/ End Side Bar -->

</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getAvatarImageURL } from "../_libraries/lib-image"

export default {
  name: 'LeftSideBar',
  methods: {
    ...mapState('auth', ['isValidLogin', 'logUser']),
    ...mapActions('auth', ['login', 'logout']),
    handleLogout(e) {
      this.logout();
    },
    logName() {
      return this.$store.state.auth.logUser.LOGName;
    },
    logAvatar() {
      //return getAvatarImageURL (this.$store.state.auth.logUser.LOGAvatar);
      return "/assets/images/no-avatar.png";
    }
  }

}


</script>

<style lang="scss" scoped></style>