<template>
  <div class="about">
    <h1>This is an Home Page</h1>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
    name:'Home',
    data () {
        return {
        }
    },
    computed: {
        ...mapState('auth', ['isValidUser']),
    },
    created () {
        // reset login status
        //this.logout();
    },
    methods: {
        /*
        ...mapActions('auth', ['login', 'logout']),
        handleSubmit (e) {
            this.submitted = true;
            const { email, password } = this;
            if (email && password) {
                this.login({ email, password })
            }
        }
        */
    }
};
</script>