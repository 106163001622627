import cgtConfig from "../cgt-config";

export default class OrderCustomer   {

      constructor(obj){
        if(obj){
          this.orcOrderID=obj.orcOrderID;
          this.orcEMail=obj.orcEMail;
          this.orcFirstName=obj.orcFirstName;
          this.orcLastName=obj.orcLastName;
          this.orcFatAsCompany=obj.orcFatAsCompany;
          this.orcFatFirstName=obj.orcFatFirstName;
          this.orcFatLastName=obj.orcFatLastName;
          this.orcFatCryKN=obj.orcFatCryKN;
          this.orcFatCryGSM=obj.orcFatCryGSM;
          this.orcFatCryVNo=obj.orcFatCryVNo;
          this.orcFatPhone=obj.orcFatPhone;
        }
    }

}