export function getLogUser (state) {
    return state.logUser;
}

export function hasValidLogin(state){
    return state.isValidLogin;
}
/*
export function userListGetter (state) {
    return state.userList;
}
/*
export function userMailListGetter (state) {
    return state.userList.filter(category => category.TYPUPIDX==0);
}
*/