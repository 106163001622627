/* eslint-disable */
import cgtAxios from "../cgt-axios";
import cgtConfig from "../cgt-config";

class CGTVisitorService {
  appVersion=cgtConfig.appVersion;
  apiURL=cgtConfig.apiURL;
  authTAG=cgtConfig.defAUTHUSERTAG;


  async getVisitors() {
    const axiosConnect = cgtAxios(this.apiURL);
    var token=JSON.parse(localStorage.getItem(this.authTAG));
    return await axiosConnect.post(`/visitors/list.php`, { ver:this.appVersion, ca:token });
  }  



}

export default new CGTVisitorService();

