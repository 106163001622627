/* eslint-disable */
import cgtAxios from "../cgt-axios";
import cgtConfig from "../cgt-config";



class SoftwareService {
  appVersion=cgtConfig.appVersion;
  apiURL=cgtConfig.apiURL;

  /*** Switches  */
  getAllSwitches() {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
    return axiosConnect.post(`/mitsoft/switch/list.php`,{ ca: token, ver:this.appVersion  });
  }

  addSwitch(data) {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
  
    return axiosConnect.post(`/mitsoft/switch/save.php`,{ ca: token, ver:this.appVersion ,...data });
  }

  delSwitch(id) {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
    return axiosConnect.post(`/mitsoft/switch/del.php`,{ ca: token, ver:this.appVersion ,id:id });
  }
  /*** Plugins  */
  getAllPlugins() {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
  
    return axiosConnect.post(`/mitsoft/plugin/list.php`,{ ca: token, ver:this.appVersion  });
  }

  addPlugin(data) {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
  
    return axiosConnect.post(`/mitsoft/plugin/save.php`,{ ca: token, ver:this.appVersion ,...data });
  }

  delPlugin(id) {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
    return axiosConnect.post(`/mitsoft/plugin/del.php`,{ ca: token, ver:this.appVersion ,id:id });
  }

  /*** Products  */
  getAllProducts() {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
  
    return axiosConnect.post(`/mitsoft/product/list.php`,{ ca: token, ver:this.appVersion  });
  }
  getPrevProducts() {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
  
    return axiosConnect.post(`/mitsoft/product/oldversion.php`,{ ca: token, ver:this.appVersion  });
  }

  getCurrentProducts() {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
  
    return axiosConnect.post(`/mitsoft/product/current.php`,{ ca: token, ver:this.appVersion  });
  }

  addProduct(data) {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
  
    return axiosConnect.post(`/mitsoft/product/save.php`,{ ca: token, ver:this.appVersion ,...data });
  }

  delProduct(id) {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
    return axiosConnect.post(`/mitsoft/product/del.php`,{ ca: token, ver:this.appVersion ,id:id });
  }

  getProduct(id) {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
    return axiosConnect.post(`/mitsoft/product/get.php`,{ ca: token, ver:this.appVersion ,id:id });
  }


  getAll() {
    return http.get("/view/products.php");
  }
/*
  get(id) {
    return http.post(`/view/product.php`,{ pId: id });
  }

  newCustomer(dataForm) {
    return http.post(`/view/register.php`,dataForm);
  }
*/
  /*** People */
  getAllPeople() {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
  
    return axiosConnect.post(`/mitsoft/people/list.php`,{ ca: token, ver:this.appVersion  });
//    return http.get("/view/categories.php");
  }
  addPerson(data) {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
    return axiosConnect.post(`/mitsoft/people/save.php`,{ ca: token, ver:this.appVersion ,...data });
  }
  updatePerson(data) {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
    return axiosConnect.post(`/mitsoft/people/save.php`,{ ca: token, ver:this.appVersion ,...data });
  }

  delPerson(id) {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
    return axiosConnect.post(`/mitsoft/people/del.php`,{ ca: token, ver:this.appVersion ,id:id });
  }

  getPerson(id) {
    var token=JSON.parse(localStorage.getItem(cgtConfig.defAUTHUSERTAG));
    const axiosConnect = cgtAxios(cgtConfig.apiURL);
    return axiosConnect.post(`/mitsoft/people/get.php`,{ ca: token, ver:this.appVersion ,id:id });
  }

  getAllUser() {
    return http.get("/view/categories.php");
  }

/*
  create(data) {
    return http.post("/tutorials", data);
  }

  update(id, data) {
    return http.put(`/tutorials/${id}`, data);
  }

  delete(id) {
    return http.delete(`/tutorials/${id}`);
  }

  deleteAll() {
    return http.delete(`/tutorials`);
  }

  findByTitle(title) {
    return http.get(`/tutorials?title=${title}`);
  }
  */
}

export default new SoftwareService();

