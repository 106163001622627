import { createApp, createSSRApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { Notifications } from '@kyvg/vue3-notification';
import { aliases, mdi } from 'vuetify/iconsets/mdi'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
          mdi,
        },
      },
    
  })


const VueApp=createApp(App);


VueApp.use(vuetify);
VueApp.use(VueSweetalert2);
VueApp.use(LoadingPlugin);
VueApp.use(Notifications);
VueApp.use(store);
VueApp.use(router);


VueApp.mount('#app');

