/*//cs-ecommerce/src/store/product/actions.js
import axios from "axios"
*/
import { error } from "jquery";
import router from "../../router";
import customerService from "../../services/crm-customers";

export function loadCustomerList({ commit }) {
  customerService.getCustomerList().then(response => {
    commit("_setCustomerListArray", response.data);
    //
    console.log(response.data);
  })
    .catch(error => {
      //console.log(error);
    });
}


// Customer
export function addCustomer({ commit, getters }, payload) {
  customerService.addCustomer(payload).then(response => {
    loadCustomerList({ commit });
    router.push('/customerlist');
  })
    .catch(error => {
      console.log(error);
      return false;
    });

}

export function updateCustomer({ commit, getters }, payload) {
  customerService.updateCustomer(payload).then(response => {
    loadCustomerList({ commit });
    router.push('/customerlist');
  })
    .catch(error => {
      console.log(error);
      return false;
    });

}


export function delCustomer({ commit, getters }, payload) {
  customerService.delCustomer(payload).then(response => {
    loadCustomerList({ commit });
    router.push('/customerlist');
    return true;
  })
    .catch(error => {
      console.log(error);
      return false;
    });

}


export function loadCustomerWaitList({ commit }) {
  customerService.getCustomerWaitList().then(response => {
    commit("_setCustomerWaitListArray", response.data);
    //
    console.log(response.data);
  })
    .catch(error => {
      //console.log(error);
    });
}


export function delWaitingCustomer({ commit, getters }, payload) {
  customerService.delWaitingCustomer(payload).then(response => {
    loadCustomerWaitList({ commit });
    router.push('/customerwaitlist');
    return true;
  })
    .catch(error => {
      console.log(error);
      return false;
    });

}