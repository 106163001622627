
export function _setTOOrderList(state, responseData) {
    state.orderList = responseData
}

export function _setTONewOrderList(state, responseData) {
    state.newOrderList = responseData

    if(responseData){
/*
       state.tokenData=new TokenData(loginData);
       if(state.tokenData){
           state.logUser=new LogUser(loginData);
           state.userCard=new UserCard(loginData);
           localStorage.setItem(cgtConfig.defAUTHUSERTAG, JSON.stringify(state.tokenData.TKNToken));  
           state.isValidLogin=true;
       }
*/            
   }

   
}

export function _setTOCompletedOrderList(state, responseData) {
    state.completedOrderList = responseData
   
}

export function _setTOCanceledOrderList(state, responseData) {
    state.canceledOrderList = responseData
   
}

