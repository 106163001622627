/*//cs-ecommerce/src/store/product/actions.js
import axios from "axios"
*/
import router from "../../router";
import authService from "../../services/auth-service";

export function login({ dispatch, commit }, { email, password }) {
  //commit('_loginRequest', { email });
  //commit('_logout');
  return new Promise((resolve, reject) => {
    authService.checkLogin(email,password).then(response => {
        commit("_loginSuccess", response);
        router.push('/');
//        resolve(response)
      })
      .catch((error) => {
        commit('_loginFailure', error);
        //dispatch('alert/error', error, { root: true });
        console.log(error);
        //reject(error.response.data)
        reject(error)
      })
  })

  /*authService.checkLogin(email,password).then(response => {
      commit("_loginSuccess", response);
      router.push('/');
    }
  )
    .catch(error => {
      commit('_loginFailure', error);
      //dispatch('alert/error', error, { root: true });
      console.log(error);
      //console.log(e);
      throw new Error(error);
      return false;
    });

*/
}

export function logout({ commit }) {
  commit('_logout');
  authService.logout();  
  router.push('/login');
}

/*export function register({ commit }) {
  commit('_loginRequest', { email });

  authService.logout();
  commit('_logout');
}

register({ dispatch, commit }, user) {
  commit('registerRequest', user);

  userService.register(user)
      .then(
          user => {
              commit('registerSuccess', user);
              router.push('/login');
              setTimeout(() => {
                  // display success message after route change completes
                  dispatch('alert/success', 'Registration successful', { root: true });
              })
          },
          error => {
              commit('registerFailure', error);
              dispatch('alert/error', error, { root: true });
          }
      );
}

*/
