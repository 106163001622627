import LogUser from "../../models/loguser";
import TokenData from "../../models/token";
import UserCard from "../../models/usercard";

export default function () {
    return {
      tokenData : TokenData,
      logUser : LogUser,
      logUserCard : UserCard,
      isValidLogin:false,

    }
  }