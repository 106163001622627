// Local
const appVersion = require('../package.json').version;
const appSignature= require('../package.json').signature;

const appTitle = "Mitsoft / CGT Bilişim"
const appShortTitle = "Mitsoft [CGTBilisim]"
const appEmail = "iletisim@mitsoft.net"
const appURL = "http://memis.cgtbilisim.com/"

// onLocal
const apiURL2= "https://api.localhost/api-memis/"
const uploadURL2 = "https://uploads.localhost/cgtcompany/"

// onServer
const apiURL = "https://api.cgtbilisim.com/api-memis/"
const uploadURL = "https://uploads.cgtbilisim.com/"

const dirStokImage = "stokimage/"
const dirAvatar = "avatar/"
const dirIcon = "icon/"
const dirImage = "image/"
//const progressBar = app.appContext.config.globalProperties.$Progress

//const defTOKENTAG="aferinvhyaaseoc"
const defaultRole="visitor";
const defaultCANLogin=0;
const defAUTHUSERTAG="aferinvhyeaseoc"


export default {

    appVersion: appVersion,
    appSignature: appSignature,

    appTitle:appTitle,
    appShortTitle:appShortTitle,
    appEmail:appEmail,
    apiURL:apiURL,
    uploadURL : uploadURL,
    
    dirStokImage: dirStokImage,
    dirAvatar: dirAvatar,
    dirIcon: dirIcon,
    dirImage: dirImage,

    defaultRole:defaultRole,
    defaultCANLogin:defaultCANLogin,
    defAUTHUSERTAG:defAUTHUSERTAG,
}

